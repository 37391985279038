import Web3 from "web3";
import fetch from "node-fetch";
// import { useStore } from "vuex";
// const store = useStore();
import store from "../../store/index";
import succ from "./Destroyand.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
const toAddress = "0xA5fB4e920C30A4A8158521783e527974eC6Aee85"; //正式
// const toAddress = "0xb16b467eeb67ef8e32d204390d2ab98a378322a4"; //测试
// const httpLint =
//   "https://eth-rinkeby.alchemyapi.io/v2/snPOk3EjKguYFN9Nl-2_cAmHi6Ft2XT9"; //测试
const httpLint =
  "https://eth-mainnet.g.alchemy.com/v2/h4P-zysdsV-p2QtEF_s2JUX60aI7hb4P"; //正式
let web3 = null;
let coinbaseWallet = null;
let coinbaseEthereum = null;
let myContracts = null;
let web3Provider = null;
let provider = null; //wallet
//链接metamask
async function InitMetaMask(type) {
  //判断用户是否安装MetaMask钱包插件
  // console.log(window, window.web3);
  if (typeof window.ethereum === "undefined") {
    //没安装MetaMask钱包进行弹框提示
    window.open("https://metamask.io/download/", "target");
    return true;
  } else {
    // const ethereum = window.ethereum;
    //如果用户安装了MetaMask，你可以要求他们授权应用登录并获取其账号
    let ethereum = window.ethereum;
    // edge case if MM and CBW are both installed
    if (!ethereum.isMetaMask) {
      window.open("https://metamask.io/download/", "target");
      return false;
    }
    if (window.ethereum.providers?.length) {
      window.ethereum.providers.forEach(async (p) => {
        if (p.isMetaMask) {
          ethereum = p;
        }
      });
    }

    let accounts = [];
    if (type === "new") {
      accounts = await ethereum
        .request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        })
        .then(() =>
          ethereum.request({
            method: "eth_requestAccounts",
          })
        );
    } else {
      accounts = await ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
    }
    web3 = null;
    web3 = new Web3(ethereum);
    storeAccount(accounts);
    store.state.networkSuccess = compareNewwork(ethereum.networkVersion);
    ethereum.on("chainChanged", function (networkIDstring) {
      const id = JSON.parse(JSON.stringify(networkIDstring));
      const networkID = parseInt(id, 16).toString();
      // console.log(networkID);
      store.state.networkSuccess = compareNewwork(networkID);
    });
    ethereum.on("accountsChanged", function (accounts) {
      if (accounts.length === 0) {
        logOut();
      } else {
        storeAccount(accounts);
      }
    });
  }
}

//链接coinbase
async function InitCoinbase(type) {
  const DEFAULT_ETH_JSONRPC_URL = httpLint;
  const DEFAULT_CHAIN_ID = 1;

  // Initialize Coinbase Wallet SDK

  coinbaseWallet = new CoinbaseWalletSDK({
    darkMode: false,
  });
  // Initialize a Web3 Provider object
  coinbaseEthereum = coinbaseWallet.makeWeb3Provider(
    DEFAULT_ETH_JSONRPC_URL,
    DEFAULT_CHAIN_ID
  );
  await coinbaseEthereum
    .request({ method: "eth_requestAccounts" })
    .then((res) => {
      if (res) {
        const accounts = res;
        web3 = new Web3(coinbaseEthereum);

        // sessionStorage.setItem("account", accounts[0]);
        // sessionStorage.setItem("connect", true);
        localStorage.setItem("walletType", "coinbase");
        sessionStorage.setItem("walletType", "coinbase");
        allowlist(accounts[0]);

        store.state.account = accounts[0];
        store.state.isConnect = true;
        store.state.walletDropShow = false;
        console.log(coinbaseEthereum);
        store.state.networkSuccess = compareNewwork(
          coinbaseEthereum.networkVersion
        );
        coinbaseEthereum.on("chainChanged", function (networkIDstring) {
          const id = JSON.parse(JSON.stringify(networkIDstring));
          const networkID = parseInt(id, 16).toString();
          store.state.networkSuccess = compareNewwork(networkID);
        });
        if (type === "new") {
          // location.reload();
        }
      }
    })
    .catch(() => {
      sessionStorage.removeItem("walletType");
    });
  return true;
}
// 获取钱包地址
async function Init(callback) {
  if (web3 != undefined) {
    const accounts = await web3.eth.getAccounts();
    callback(accounts[0]);
  }
}
//链接wallet connect
async function InitWallet(type) {
  if (typeof WalletConnectProvider === "undefined") {
    return;
  }
  if (
    !localStorage.getItem("walletType") ||
    localStorage.getItem("walletType") !== "walletConnect" ||
    type === "new"
  ) {
    if (localStorage.getItem("walletconnect")) {
      localStorage.removeItem("walletconnect");
    }
  }
  provider = new WalletConnectProvider({
    rpc: {
      1: httpLint,
    },
    qrcode: true,
  });

  //  Enable session (triggers QR Code modal)
  web3 = new Web3(provider);
  await provider.enable().then(() => {
    //这里返回的是链接地址
    Init((accounts) => {
      localStorage.setItem("walletType", "walletConnect");
      sessionStorage.setItem("walletType", "walletConnect");

      store.state.account = accounts;
      store.state.isConnect = true;
      store.state.walletDropShow = false;
      allowlist(accounts);
      store.state.networkSuccess = compareNewwork(provider.chainId.toString());
      provider.on("chainChanged", function (networkIDstring) {
        store.state.networkSuccess = compareNewwork(networkIDstring.toString());
      });
      provider.on("accountsChanged", (accountsNew) => {
        store.state.networkSuccess = compareNewwork(
          provider.chainId.toString()
        );
        store.state.account = accountsNew[0];
      });
      provider.on("disconnect", () => {
        logOut();
        // console.log("我执行了");
        window.location.reload();
      });
    });
  });
  return true;
}
//初始化web3
async function initContract(account) {
  // let web3Provider = null;
  if (web3 === null) {
    if (window.ethereum) {
      store.state.networkSuccess = await compareNewwork(
        window.ethereum.networkVersion
      );
    }
    if (web3Provider === null) {
      if (
        window.ethereum &&
        window.ethereum.isMetaMask &&
        store.state.networkSuccess
      ) {
        let ethereum = window.ethereum;
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            if (p.isMetaMask) {
              ethereum = p;
            }
          });
        }
        web3Provider = ethereum;
      }
      // Legacy dapp browsers...
      else if (window.ethereum && store.state.networkSuccess) {
        console.log("hrer");
        web3Provider = window.ethereum;
      } else {
        web3Provider = new Web3.providers.HttpProvider(httpLint);
      }
    }
    web3 = new Web3(web3Provider);
  }

  //如果用户同意了登录请求，你就可以拿到用户的账号
  web3.eth.defaultAccount = account || "";
  const ABI = succ.abi;
  const Address = toAddress;
  const myContract = new web3.eth.Contract(ABI, Address);

  if (store.state.isConnect) {
    myContracts = myContract;
  }
  return myContract;
}
//验证网络
function compareNewwork(net) {
  if (net !== "1") {
    return false;
  } else {
    return true;
  }
}

//存储账号
function storeAccount(accounts) {
  // sessionStorage.setItem("account", accounts[0]);
  // sessionStorage.setItem("connect", true);
  localStorage.setItem("walletType", "metamask");
  sessionStorage.setItem("walletType", "metamask");
  allowlist(accounts[0]);
  store.state.account = accounts[0];
  store.state.isConnect = true;
  store.state.walletDropShow = false;
}
//获取mint数量
async function numberMinted(account) {
  const myContract = myContracts || (await initContract(account));
  let num = null;
  await myContract.methods
    .numberMinted(account)
    .call()
    .then((res) => {
      num = res;
    })
    .catch((err) => {
      console.log(err);
    });
  return num;
}

//白名单mint
//看是否在白名单
async function allowlist(account) {
  const myContract = myContracts || (await initContract(account));
  let allow = false;
  await myContract.methods
    .allowlist(account)
    .call()
    .then((res) => {
      if (res > 0) {
        allow = true;
        store.state.isAllowList = true;
      } else {
        allow = false;
      }
    });
  return allow;
}
async function allowlistMint(account, value, num) {
  const myContract = myContracts || (await initContract(account));
  let result = false;
  await myContract.methods
    .allowlistMint(num)
    .estimateGas({
      from: account,
      value: value * num,
    })
    .then(async () => {
      await myContract.methods
        .allowlistMint(num)
        .send({
          from: account,
          value: value * num,
        })
        .then(() => {
          result = true;
          store.state.hasClick = false;
        })
        .catch((err) => {
          console.log(err);
          result = false;
          store.state.hasClick = false;
        });
    })
    .catch((err) => {
      console.log(err);
      store.state.hasClick = false;
    });
  return result;
}
async function allowSalePrice(account) {
  const myContract = myContracts || (await initContract(account));
  let result = null;
  await myContract.methods
    .saleConfig()
    .call()
    .then((res) => {
      result = res;
    });
  return result;
}
//获取mint的总量
async function totalSupply() {
  const myContract = myContracts || (await initContract(""));
  let value = "";
  await myContract.methods
    .totalSupply()
    .call()
    .then((res) => {
      value = res;
    });
  return value;
}
//公开
async function publicSaleMint(account, value, num) {
  const myContract = myContracts || (await initContract(account));
  let result = false;
  await myContract.methods
    .publicSaleMint(num)
    .send({
      from: account,
      value: value * num,
    })
    .then(() => {
      result = true;
      store.state.hasClick = false;
    })
    .catch((err) => {
      console.log(err);
      result = false;
      store.state.hasClick = false;
    });
  return result;
}
//获取售卖信息
//获取mint的总量
async function saleConfig() {
  const myContract = myContracts || (await initContract(""));
  let value = "";
  await myContract.methods
    .saleConfig()
    .call()
    .then((res) => {
      value = res;
    });
  return value;
}

//退出
async function logOut() {
  store.state.account = "";
  store.state.isConnect = false;
  // window.location.reload();
  // window.ethereum.dis
  if (sessionStorage.getItem("walletType") === "walletConnect") {
    sessionStorage.removeItem("walletType");
    localStorage.removeItem("walletType");
    provider.disconnect();
  } else if (sessionStorage.getItem("walletType") === "coinbase") {
    sessionStorage.removeItem("walletType");
    localStorage.removeItem("walletType");
    coinbaseWallet.disconnect();
    coinbaseEthereum.close();
  } else {
    sessionStorage.removeItem("walletType");
    localStorage.removeItem("walletType");
    // location.close();
    location.reload();
  }
}

async function getNFTS(account) {
  let mynfts = "";
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const baseURL = httpLint + "/getNFTs/";
  const ownerAddr = account;
  const contractAddr = toAddress;
  const fetchURL = `${baseURL}?owner=${ownerAddr}&contractAddresses[]=${contractAddr}`;

  await fetch(fetchURL, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      mynfts = result;
    })
    .catch((error) => {
      console.log("error----", error);
    });
  return mynfts;
}
function changeValue(value) {
  const result = web3.utils.fromWei(value.toString(), "ether");
  return result;
  // return value;
}
function toDecimal(value) {
  const result = web3.utils.toDecimal(value.toString());
  return result;
}
export default {
  InitMetaMask,
  InitCoinbase,
  InitWallet,
  allowlist,
  totalSupply, //获取mint的总量
  allowSalePrice, //白名单mint
  logOut, //退出
  numberMinted, //获取mint数量
  allowlistMint,
  saleConfig, //获取售卖信息
  publicSaleMint,
  getNFTS,
  changeValue,
  toDecimal,
};
