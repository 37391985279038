<template>
  <div id="app">
    <Nav />
    <!-- <keep-alive> -->
    <!-- <router-view /> -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
    <!-- </keep-alive> -->
  </div>
  <Wallet
    v-if="$store.state.walletDropShow"
    @close="$store.state.walletDropShow = false"
  />
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
// import Loading from "@/components/Loading.vue";
import Wallet from "@/components/wallet.vue";
import Nav from "@/components/Nav.vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    Nav,
    Wallet,
  },
  setup() {
    const state = reactive({
      loading: false,
    });
    const store = useStore();
    onMounted(() => {
      window.onload = function () {
        setTimeout(() => {
          state.loading = false;
        }, 5000);
      };
    });
    watch(
      () => store.state.account,
      (newVal) => {
        if (newVal && !localStorage.getItem(newVal)) {
          localStorage.setItem(newVal, "new");
        }
        store.state.openState = localStorage.getItem(newVal);
      }
    );
    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss">
@import "~@/common/css/common.scss";
@import "~@/common/css/font.css";

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "PF_bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: $bg;
  min-height: 100vh;
  position: relative;
  color: $main;
}

.mobile_bg {
  display: none;
}

@media only screen and (max-width: 765px) {
  .logo {
    width: 21.5rem;
    height: 21.5rem;
    display: block;
    position: fixed;
    margin-left: 0;
    top: 9rem;
    left: unset;
    right: 10rem;
    z-index: 3;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mobile_bg {
    display: block;
    height: 100vh;
    width: calc(100% - 20rem);
    // background: red;
    background-image: url("./assets/imgs/mobile_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: fixed;
    top: 80rem;
    left: 10rem;
    z-index: 0;
  }
}
</style>
