<template>
  <div class="drop">
    <div class="card">
      <div class="rightInfo">
        <div class="close" @click="handleClose">
          <img src="@/assets/mint/close_p.png" alt="" />
        </div>
        <h1 class="species">WALLETS</h1>
        <h2 class="tips">
          <span>Choose Your Blockchain Wallet</span>
        </h2>
        <ul class="wallets" @click.stop.prevent="chooseWallet">
          <li @click="chooseWallet('MetaMask')">
            <div class="icon">
              <img style="width: 7.5rem" src="@/assets/wallet/1.png" alt="" />
            </div>
            <div class="title">MetaMask</div>
          </li>
          <li @click.stop.prevent="chooseWallet('Coinbase')">
            <div class="icon">
              <img style="width: 6.7rem" src="@/assets/wallet/2.png" alt="" />
            </div>
            <div class="title">
              Coinbase<br />
              Wallet
            </div>
          </li>
          <li @click.stop.prevent="chooseWallet('Wallet')">
            <div class="icon">
              <img style="width: 8.6rem" src="@/assets/wallet/3.png" alt="" />
            </div>
            <div class="title">
              Wallet<br />
              Connect
            </div>
          </li>
        </ul>
        <!-- <p class="info">{{ cardInfo.info }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import Web3 from "@/common/js/utils";
export default defineComponent({
  setup(props, { emit }) {
    const data = reactive({});
    const handleClose = () => {
      emit("close", false);
    };
    function isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
    const chooseWallet = (type) => {
      if (type === "MetaMask") {
        if (isMobile()) {
          // console.log('mobile')
          Web3.InitMetaMask();
        } else {
          Web3.InitMetaMask("new");
        }
      } else if (type === "Coinbase") {
        Web3.InitCoinbase("new");
      } else if (type === "Wallet") {
        Web3.InitWallet("new");
      }
    };
    return {
      ...toRefs(data),
      handleClose,
      chooseWallet,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
.drop {
  .card {
    background: $title;
    display: inline-block;
    border-radius: 2.5rem;
    padding: 0 2.5rem;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    .rightInfo {
      display: flex;
      flex-flow: column;
      flex: 1;
      overflow: hidden;
      // min-width: 45rem;
      .close {
        width: 3rem;
        height: 3rem;
        position: relative;
        margin-top: 2.1rem;
        align-self: flex-end;
        right: 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .species {
        color: $sub;
        font-size: 5.6rem;
        font-family: "RB";
        // margin-top: 0.9rem;s
      }
      .tips {
        margin-bottom: 4.1rem;
        // margin-top: 1.9rem;
        white-space: nowrap;

        span {
          font-family: "RB";
          font-size: 2.3rem;
        }
      }
      .wallets {
        margin-bottom: 6.5rem;
        padding: 0 7.2rem;
        li {
          float: left;
          cursor: pointer;
          .icon {
            background: $main;
            width: 10rem;
            height: 10rem;
            border-radius: 2.7rem;
            display: table-cell;
            vertical-align: middle;
            img {
              display: inline-block;
              height: auto;
              vertical-align: bottom;
            }
          }
          .title {
            font-family: "RB";
            font-size: 1.7rem;
            color: $sub;
            line-height: 1.7rem;
            margin-top: 1.4rem;
          }
          &:not(:last-child) {
            margin-right: 3.8rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 765px) {
  .drop {
    .card {
      width: fit-content;
      background: $title;
      display: inline-block;
      border-radius: 1.6rem;
      padding: 0 1.4rem;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      .rightInfo {
        display: flex;
        flex-flow: column;
        flex: 1;
        overflow: hidden;
        // min-width: 45rem;
        .close {
          width: 1.4rem;
          height: 1.4rem;
          position: relative;
          margin-top: 1.3rem;
          align-self: flex-end;
          right: 0;
          cursor: pointer;
          img {
            content: url("../assets/mint/close_mini.png");
          }
        }
        .species {
          color: $sub;
          font-size: 3rem;
          font-family: "RB";
          margin-top: 0.18rem;
        }
        .tips {
          margin-bottom: 3.8rem;
          margin-top: 1rem;
          white-space: nowrap;

          span {
            font-family: "RB";
            font-size: 1.3rem;
          }
        }
        .wallets {
          margin-bottom: 3rem;
          padding: 0 2.6rem;
          li {
            float: left;
            cursor: pointer;
            .icon {
              background: $main;
              width: 5.7rem;
              height: 5.6rem;
              border-radius: 1.2rem;
              display: table-cell;
              // vertical-align: middle;
              img {
                display: inline-block;
                height: auto;
                vertical-align: middle;
              }
            }
            &:nth-child(1) {
              img {
                width: 4.3rem !important;
              }
            }
            &:nth-child(2) {
              img {
                width: 3.8rem !important;
              }
            }
            &:nth-child(3) {
              img {
                width: 4.9rem !important;
              }
            }
            .title {
              font-family: "RB";
              font-size: 1rem;
              color: $sub;
              line-height: 1rem;
              margin-top: 1.1rem;
            }
            &:not(:last-child) {
              margin-right: 1.9rem;
            }
          }
        }
      }
    }
  }
}
</style>
