import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import lazyPlugin from "vue3-lazy";
import "@/common/css/font.css";
createApp(App)
  .use(store)
  .use(router)
  .use(lazyPlugin, {
    loading: require("@/assets/gif/loading.gif"), // 图片加载时默认图片
    error: require("@/assets/gif/loading.gif"), // 图片加载失败时默认图片
  })
  .mount("#app");
