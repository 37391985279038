<template>
  <div class="pcnav">
    <router-link to="/" class="logo" v-show="$route.path === '/'"
      ><img src="@/assets/home/logo.png" alt=""
    /></router-link>
    <ul class="linkList" :style="{ opacity: $route.path !== '/' ? 1 : 0 }">
      <li>
        <a href="https://opensea.io/collection/destroyand" target="_blank">
          <img src="@/assets/link/opensea.png" alt="" />
        </a>
      </li>
      <li style="margin-left: 0">
        <a href="https://twitter.com/destroyand_" target="_blank">
          <img src="@/assets/link/twitter.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://discord.com/invite/destroyand" target="_blank">
          <img src="@/assets/link/discord.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/destroyand_" target="_blank">
          <img src="@/assets/link/ins.png" alt="" />
        </a>
      </li>
    </ul>
    <ul class="myNav">
      <li>
        <router-link to="/"><span>HOME</span></router-link>
      </li>
      <li>
        <router-link to="/gallery"><span>GALLERY</span> </router-link>
      </li>
      <li>
        <router-link to="/story"><span>OUR STORY</span> </router-link>
      </li>
      <li>
        <router-link to="/roadmap"><span>ROADMAP</span></router-link>
      </li>
      <li>
        <router-link to="/faq"><span>FAQ</span></router-link>
      </li>
      <li>
        <router-link to="/team"><span>TEAM</span></router-link>
      </li>
      <li>
        <router-link to="/mint"><span>MINT</span> </router-link>
      </li>
    </ul>
    <div class="wallet">
      <div
        v-if="$store.state.isConnect"
        class="account"
        :class="{ showlogout: showDisconnect }"
      >
        <span
          v-if="$store.state.networkSuccess"
          @click="showDisconnect = !showDisconnect"
          >{{ getSubStr($store.state.account) }}</span
        >
        <span
          @click="showDisconnect = !showDisconnect"
          v-else
          style="border-color: #ff4848; color: #ff4848"
          >Wrong network</span
        >
        <button
          class="logout"
          v-show="showDisconnect"
          @click.stop.prevent="logout"
        >
          <img src="@/assets/mint/logout.png" alt="" /> Log Out
        </button>
      </div>
      <img
        @click.stop.prevent="$store.state.walletDropShow = true"
        v-else
        src="@/assets/mint/wallet.png"
        alt=""
      />
    </div>
  </div>
  <div class="mobile">
    <div class="button">
      <button v-if="!showMenu" @click="showMenu = !showMenu">
        <div
          class="line react-reveal"
          :class="{
            bindColor: darkPage.indexOf($route.path) > -1,
          }"
        ></div>
      </button>
      <router-link to="/" class="logo" v-if="darkPage.indexOf($route.path) > -1"
        ><img src="@/assets/imgs/mobile_logo.png" alt=""
      /></router-link>
      <div v-else-if="$route.path === '/mint'" class="wallet">
        <div
          v-if="$store.state.isConnect"
          class="account"
          :class="{ showlogout: showDisconnect }"
        >
          <span @click="showDisconnect = !showDisconnect">{{
            getSubStr($store.state.account)
          }}</span>
          <button
            class="logout"
            v-show="showDisconnect"
            @click.stop.prevent="logout"
          >
            <img src="@/assets/mint/logout.png" alt="" /> Log Out
          </button>
        </div>
        <img
          v-else
          @click.stop.prevent="$store.state.walletDropShow = true"
          src="@/assets/mint/wallet_dark.png"
          alt=""
        />
      </div>
      <router-link to="/" class="logo" v-else-if="$route.path !== '/story'"
        ><img src="@/assets/imgs/mobile_logo_dark.png" alt=""
      /></router-link>
    </div>

    <div class="navList" :class="{ showNav: showMenu }">
      <div class="close" @click="showMenu = !showMenu"></div>
      <router-link to="/">HOME</router-link>
      <router-link to="/gallery">GALLERY</router-link>
      <router-link to="/story">OUR STORY</router-link>
      <router-link to="/roadmap">ROADMAP</router-link>
      <router-link to="/faq">FAQ</router-link>
      <router-link to="/team">TEAM</router-link>
      <router-link to="/mint">MINT</router-link>
      <div class="bottom">
        <ul class="linkList">
          <li>
            <a href="https://opensea.io/collection/destroyand" target="_blank">
              <img src="@/assets/mobile_link/opensea.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/destroyand_" target="_blank">
              <img src="@/assets/mobile_link/twitter.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/destroyand" target="_blank">
              <img src="@/assets/mobile_link/discord.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/destroyand_" target="_blank">
              <img src="@/assets/mobile_link/ins.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import Web3 from "@/common/js/utils";

export default defineComponent({
  setup() {
    const data = reactive({
      showMenu: false,
      showDrop: false,
      showDisconnect: false,
      darkPage: ["/", "/team"],
    });
    const route = useRoute();
    const getSubStr = (str) => {
      var subStr1 = str.substr(0, 5);
      var subStr2 = str.substr(str.length - 3, 3);
      var subStr = subStr1 + "..." + subStr2;
      return subStr;
    };
    const logout = () => {
      Web3.logOut();
      data.showDisconnect = false;
    };
    watch(
      () => route.matched,
      (newVal) => {
        if (newVal) {
          data.showMenu = false;
        }
      }
    );
    return {
      ...toRefs(data),
      getSubStr,
      logout,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
.mobile {
  display: none;
}
@keyframes logo-rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(-360deg);
  }
}
.pcnav {
  user-select: none;
  display: flex;
  height: 15.7rem;
  position: relative;
  z-index: 1;
  background: #000;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0) 0%,
      rgba(72, 36, 128, 0.7) 100%
    );
  }
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  padding: 0 21rem 0 14rem;
  .logo {
    width: 20.5rem;
    height: 7rem;
    display: block;
    position: fixed;
    margin-left: 0;
    top: 5rem;
    left: 14rem;
    z-index: 4;
    // animation: logo-rotate linear 4s infinite;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .myNav {
    padding-top: 8.1rem;
    text-align: right;
    // width: 163rem;
    // margin-left: 20.2rem;
    white-space: nowrap;
    z-index: 3;
    li {
      float: left;
      margin-left: 9.5rem;
      a {
        span {
          color: $title;
          font-size: 2.2rem;
          transition: all 0.3s;
          display: inline-block;
        }

        span {
          position: relative;
          &:hover {
            color: $sub;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 6.7rem;
              top: -4rem;
              right: -5rem;
              height: 6.6rem;
              background-image: url("../assets/story/star_p.png");
              background-size: 100%;
              background-repeat: no-repeat;
              transform: scale(0.2);
            }
          }
        }
      }
      .router-link-active {
        span {
          color: $sub;
          &::before {
            content: "";
            position: absolute;
            display: block;
            width: 6.7rem;
            top: -4rem;
            right: -5rem;
            height: 6.6rem;
            background-image: url("../assets/story/star_p.png");
            background-size: 100%;
            background-repeat: no-repeat;
            transform: scale(0.2);
          }
        }
      }

      &:first-child {
        span {
          margin-left: 0;
        }
      }
      position: relative;
      cursor: pointer;
      &:not(:last-child)::after {
        content: "";
        width: 1.2rem;
        height: 2.4rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -5.2rem;
        background: linear-gradient(
          -71deg,
          transparent 43.5%,
          #8f48ff 43.5%,
          #8f48ff 56.5%,
          transparent 56.5%
        );
        cursor: default;
      }
    }
  }
  .wallet {
    position: absolute;
    right: 0;
    margin-top: 8.4rem;
    right: 10.5rem;
    cursor: pointer;
    color: $sub;
    .account {
      margin-right: -5rem;
      // margin-top: 0.3rem;
      font-size: 1.5rem;
      &.showlogout {
        display: flex;
        flex-direction: column;
        background: $sub;
        width: 10.5rem;
        span {
          display: inline-block;
          width: fit-content;
          margin: 0.3rem auto 1.3rem;
          color: $main;
          border-left: 0.2rem solid $main;
          border-right: 0.2rem solid $main;
        }
      }
      span {
        font-family: "RB";
        line-height: 1.7rem;
        border-left: 0.2rem solid $sub;
        border-right: 0.2rem solid $sub;
        padding: 0 0.7rem;
      }
      .logout {
        font-family: "RB";
        background: rgba(255, 255, 255, 0.18);
        padding: 0.8rem 0 0.7rem;
        font-size: 1.4rem;
        color: $title;
        cursor: pointer;
        img {
          width: 1.6rem;
          height: 1.3rem;
          display: inline-block;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.41);
        }
      }
    }
    img {
      width: 3.2rem;
      height: 2.8rem;
      // width: 100%;
      // height: 100%;
      display: block;
    }
  }
  .linkList {
    display: inline-flex;
    margin-top: 6.7rem;
    position: relative;
    z-index: 3;
    li {
      margin-right: 3.1rem;
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        span {
          font-size: 5rem;
          font-family: "PF_light";
          margin-top: 0;
          flex: 1;
        }
        &:hover {
          transform: unset;
          opacity: 0.7;
          background: unset;
        }
        &:active {
          transform: unset;
          opacity: 0.7;
        }
      }
      img {
        width: 4.4rem;
        height: 4.4rem;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 765px) {
  .pcnav {
    display: none;
  }
  .mobile {
    display: block;
    text-align: left;
    .navList {
      display: flex;
      flex-flow: column;
      position: fixed;
      top: 0;
      // background: rgba(0, 0, 0, 0.85);
      padding: 0 3rem;
      z-index: 2000;
      height: 100vh;
      width: 100vw;
      left: 0;
      transform: translateX(-100vw);
      transition: all 0.3s;
      overflow: hidden;
      &.showNav {
        transform: translateX(0);
      }
      &::after {
        content: "";
        position: absolute;
        width: 110%;
        height: 110%;
        background: rgba(0, 0, 0, 0.85);
        -webkit-backdrop-filter: blur(0.5rem);
        backdrop-filter: blur(0.5rem);
        filter: brightness(0.85);
        top: -5%;
        left: -5%;
        z-index: -1;
      }
      a {
        display: inline-block;
        color: $sub;
        font-size: 4rem;
        transition: all 0.3s;
        display: inline-block;
        cursor: pointer;
      }
      span {
        display: inline-block;
        height: 7.6rem;
        line-height: 7.6rem;
        color: #dcdddd;
        margin-top: 20rem;
        font-size: 9rem;
        transition: all 0.3s;
        display: inline-block;
        cursor: pointer;
      }
      .bottom {
        margin-top: 4.2rem;
        .join {
          font-size: 6rem;
          // margin: 5.5rem auto 2.7rem;
          color: #dcdddd;
          padding-bottom: 1.4rem;
          border-bottom: 0.05rem solid #dcdddd;
        }
      }
    }
    .linkList {
      display: inline-flex;
      li {
        margin-right: 3.2rem;
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: auto;
          &:hover {
            transform: unset;
            opacity: 0.7;
            background: unset;
          }
          &:active {
            transform: unset;
            opacity: 0.7;
          }
        }
        img {
          width: 4.5rem;
          height: 4.5rem;
          display: block;
        }
      }
    }
  }

  .button {
    // text-align: end;
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    button {
      height: 7rem;
      margin-left: 2rem;
      .line {
        width: 2.4rem;
        height: 0.3rem;
        border-radius: 0.6rem;
        background-color: $main;
        transition: all 0.3s ease 0s;
        transform: rotate(0deg);
        opacity: 1;
        position: relative;
        &.bindColor {
          background-color: $sub;
          &::before,
          &::after {
            background-color: $sub;
          }
        }
        // z-index: 2;
        &::before,
        &::after {
          content: "";
          position: absolute; /*方便进行定位*/
          width: 2.4rem;
          height: 0.3rem;
          left: 0;
          border-radius: 0.6rem;
          background-color: $main;
          transition: all 0.3s ease 0s;
          transform: rotate(0deg);
          opacity: 1;
        }
        &::before {
          transform: translateY(-0.7rem);
        }
        &::after {
          transform: translateY(0.7rem);
        }
      }
    }
    .logo {
      width: 8rem;
      height: 2.7rem;
      top: 2rem;
      right: 2rem;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .wallet {
      position: absolute;

      margin-top: 0;
      right: 2.5rem;
      top: 2.2rem;
      cursor: pointer;
      .account {
        margin-right: 0rem;
        margin-top: 0.3rem;
        font-size: 1.5rem;
        &.showlogout {
          display: flex;
          flex-direction: column;
          background: $main;
          width: 9.1rem;
          span {
            display: inline-block;
            width: fit-content;
            margin: 0.3rem auto 0.3rem;
            color: $title;
            border-left: 0.1rem solid $title;
            border-right: 0.1rem solid $title;
          }
        }
        span {
          color: $title;
          font-size: 1.2rem;
          line-height: 1.5rem;
          border-left: 0.1rem solid $title;
          border-right: 0.1rem solid $title;
          padding: 0 0.3rem;
        }
        .logout {
          height: unset;
          margin-left: 0;
          font-family: "RB";
          background: rgba(143, 72, 255, 0.79);
          padding: 0.3rem 0 0.4rem;
          color: $title;
          cursor: pointer;
          img {
            width: 1.6rem;
            height: 1.3rem;
            display: inline-block;
            vertical-align: middle;
          }
          &:hover {
            background: rgba(255, 255, 255, 0.41);
          }
        }
      }
      img {
        width: 2.1rem;
        height: 1.9rem;
        display: block;
      }
    }
  }
  .close {
    width: 1rem;
    height: 7rem;
    // right: 0;
    // align-self: flex-end;
    margin-bottom: 7.8rem;
    position: relative;
    margin-left: 0.6rem;
    &::before,
    &::after {
      content: "";
      position: absolute; /*方便进行定位*/
      height: 3.2rem;
      width: 0.3rem;
      top: 2rem;
      right: 0.18rem; /*设置top和right使图像在20*20框中居中*/
      background: $sub;
    }
    &::before {
      transform: rotate(60deg); /*进行旋转*/
    }
    &::after {
      transform: rotate(-60deg);
    }
  }
}
</style>
