import { createStore } from "vuex";

export default createStore({
  state: {
    account: sessionStorage.getItem("account") || "",
    openState: "",
    loading: true,
    isConnect: sessionStorage.getItem("connect") ? true : false,
    isEnd: false,
    walletDropShow: false,
    walletType: sessionStorage.getItem("walletType"),
    networkSuccess: true,
    hasClick: false,
  },
  mutations: {},
  actions: {},
  modules: {},
});
